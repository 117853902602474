import { useSetAtom } from 'jotai';
import { withQuery } from 'src/hooks/use-locale';
import { toggleFavSportAtom, toggleFavTourAtom } from 'src/store/sport';
import { Paths } from 'src/utils/constants/path';
import { isOutright } from 'src/utils/helpers/fixture';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { SportTypeEnum } from 'src/utils/types/sport';

export const useNavList = (type: SportTypeEnum) => {
  const toggleFavLeague = useSetAtom(toggleFavTourAtom);
  const toggleFavSport = useSetAtom(toggleFavSportAtom);

  const redirectTo = (slug?: string, tournamentId?: string) => {
    let pathname = generatePath(Paths.MetaSport, {
      sportType: type,
      slug,
      eventId: tournamentId
        ? isOutright(tournamentId)
          ? 'league'
          : 'futures'
        : undefined,
    });

    if (tournamentId) {
      pathname = withQuery(pathname, { tournament: tournamentId });
    }

    return pathname;
  };

  const handleSelectFav = ({
    sportId,
    tournamentId,
  }: {
    sportId?: string;
    tournamentId?: string;
  }) => {
    if (tournamentId) {
      return toggleFavLeague(tournamentId);
    }

    if (sportId) {
      return toggleFavSport(sportId);
    }
  };

  return { redirectTo, handleSelectFav };
};
