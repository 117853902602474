import { Trans } from '@lingui/macro';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useToggle } from 'react-use';
import { getTournamentCount } from 'src/api/tournament';
import ArrowUp from 'src/assets/icons/arrow-up-2.svg';
import SpecialsIcon from 'src/assets/icons/casino-config.svg';
import TournamentsIcon from 'src/assets/icons/sport-config.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { useMyRouter } from 'src/hooks/use-locale';
import { Paths } from 'src/utils/constants/path';
import { getTournamentCountKey } from 'src/utils/helpers/swr';
import useSWR from 'swr';
import { useStyles } from '../sport-list/styles';

export const Promotion: React.FC = (props) => {
  const { classes, cx } = useStyles();
  const [open, toggle] = useToggle(true);
  const router = useMyRouter();

  const { data: tourCount } = useSWR(
    getTournamentCountKey(),
    getTournamentCount,
  );
  const hasTournament = !_.isEmpty(tourCount);

  return (
    <List disablePadding className={classes.list}>
      <ListItemButton onClick={toggle} className={classes.title}>
        <Typography variant="18" sx={{ flex: 1 }} fontWeight="bold">
          <Trans id="promotions">Promotions</Trans>
        </Typography>
        <ListItemIcon>
          <ArrowUp width={16} className={cx(classes.arrow, open && 'rotate')} />
        </ListItemIcon>
      </ListItemButton>

      <Collapse in={open} mountOnEnter unmountOnExit={false}>
        <List disablePadding className={classes.body}>
          {[
            {
              label: <Trans id="specials">Specials</Trans>,
              value: Paths.Specials,
              image: SpecialsIcon,
            },
          ]
            .concat(
              hasTournament
                ? [
                    {
                      label: <Trans id="tournaments">Tournaments</Trans>,
                      value: Paths.Tournaments,
                      image: TournamentsIcon,
                    },
                  ]
                : [],
            )
            .map((x) => {
              return (
                <ListItemButton
                  key={x.value}
                  component={MyLink}
                  noLinkStyle
                  href={x.value}
                  selected={x.value === router.pathname}>
                  <ListItemIcon sx={{ mr: 1 }}>
                    {React.createElement(x.image, { width: 16, height: 16 })}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={x.label}
                  />
                </ListItemButton>
              );
            })}
        </List>
      </Collapse>
    </List>
  );
};
