import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Badge,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useToggle } from 'react-use';
import { CampaignEvent, getCampaigns } from 'src/api/campaign';
import ArrowUp from 'src/assets/icons/arrow-up-2.svg';
import FireIcon from 'src/assets/icons/hot-bet.svg?url';
import { FavoriteButton } from 'src/components/core/favorite-button';
import { MyLink, MyLinkProps } from 'src/components/core/mine/my-link';
import {
  ErrorTypes,
  parseBsError,
} from 'src/components/modules/bet-slip-drawer/components/shared/error-message';
import { useHotEvents } from 'src/components/modules/hot-events';
import { useLeftNavHidden, useMetaParams } from 'src/hooks/use-meta-params';
import { addCampaignAtom } from 'src/store/bet-slip';
import { sportIdDirAtom } from 'src/store/sport';
import { Paths } from 'src/utils/constants/path';
import { CampaignStatus } from 'src/utils/helpers/campaign';
import { getCampaignsKey } from 'src/utils/helpers/swr';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { MySport, MyTournament, SportTypeEnum } from 'src/utils/types/sport';
import useSWR from 'swr';
import HotEventItem from '../hot-event-item';
import { LiveNowIndicator } from '../livenow-indicator';
import { useNavList } from '../use-nav-list';
import { useStyles } from './styles';
export type WithFav<T> = T & { __favorite: boolean };

interface SportListProps {
  items?: WithFav<MySport>[];
  tournaments?: WithFav<MyTournament>[];
  type: SportTypeEnum;
  liveCount: number;

  header: React.ReactNode;
  featured: React.ReactNode;
  liveTitle: React.ReactNode;
  title: React.ReactNode;
}

const useCampaigns = (disable: boolean) => {
  const { data } = useSWR(
    disable
      ? null
      : getCampaignsKey({
          page: 1,
          size: -1,
          status: CampaignStatus.ACTIVE,
          sortBy: 'endDateTime',
          orderBy: 'ASC',
        }),

    ([, params]) => getCampaigns(params),
  );

  const outcomes: { event: CampaignEvent; outcomeId: string; name: string }[] =
    [];

  for (const e of data?.items ?? []) {
    for (const m of e.markets) {
      for (const o of m.outcomes) {
        if (outcomes.length >= 5) break;

        outcomes.push({ event: e, outcomeId: o._id, name: o.name });
      }
    }
  }

  return outcomes;
};

export const SportList: React.FCC<SportListProps> = (props) => {
  const {
    type,
    liveCount,
    header,
    liveTitle,
    featured,
    title,
    items = [],
    tournaments = [],
  } = props;
  const { classes, cx } = useStyles();
  const [open, toggle] = useToggle(true);
  const { sportType, slug } = useMetaParams();
  const { handleSelectFav, redirectTo } = useNavList(type);
  const [_expand, toggleExpand] = useToggle(false);
  const { hidden, isLive } = useLeftNavHidden(type);
  const sports = useAtomValue(sportIdDirAtom);
  const { i18n } = useLingui();

  const outcomes = useCampaigns(type !== SportTypeEnum.Sport || hidden);
  const { data: hotEvents } = useHotEvents();
  const addToCart = useSetAtom(addCampaignAtom);
  const { enqueueSnackbar } = useSnackbar();

  if (hidden) {
    return null;
  }

  const favLength = _.filter(items, { favorite: true }).length;
  const sliceLength = favLength > 5 ? favLength : 5;

  const showAll = type === sportType || _expand;

  return (
    <List disablePadding className={classes.list}>
      <ListItemButton onClick={toggle} className={classes.title}>
        <Typography variant="18" sx={{ flex: 1 }} fontWeight="bold">
          {header}
        </Typography>
        <ListItemIcon>
          <ArrowUp width={16} className={cx(classes.arrow, open && 'rotate')} />
        </ListItemIcon>
      </ListItemButton>

      <Collapse in={open} mountOnEnter unmountOnExit={false}>
        <List disablePadding className={classes.body}>
          <LiveNowIndicator
            selected={isLive}
            pathname={generatePath(Paths.MetaLiveSport, { sportType: type })}
            count={liveCount}
            liveNowTitle={liveTitle}
          />

          {/* hot bets */}
          {outcomes.length > 0 && (
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: '16',
                  color: 'custom.content.2',
                }}
                primary={<Trans id="home.hot_bets">Hot bets</Trans>}
              />
            </ListItem>
          )}
          {outcomes.map((x) => {
            return (
              <ListItemButton
                className="normal"
                key={x.outcomeId}
                onClick={async () => {
                  try {
                    await addToCart(x);
                  } catch (err: any) {
                    if (err.self_code === ErrorTypes.SelfExclude) {
                      enqueueSnackbar(
                        parseBsError({ code: ErrorTypes.SelfExclude, i18n }),
                        {
                          variant: 'error',
                          className: 'ubet-err',
                        },
                      );
                    }
                  }
                }}>
                <ListItemIcon sx={{ mr: 1 }}>
                  <Box
                    component="img"
                    src={FireIcon.src}
                    sx={{
                      width: 16,
                      height: 16,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={x.name}
                />
              </ListItemButton>
            );
          })}

          {/* hot events */}
          {type === SportTypeEnum.Sport && hotEvents?.meta.total_count > 0 && (
            <>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    variant: '16',
                    color: 'custom.content.2',
                  }}
                  primary={<Trans id="sportsbook.hot_events">Hot events</Trans>}
                />
              </ListItem>

              {hotEvents?.items.map((x) => (
                <HotEventItem key={x._id} data={x} />
              ))}
            </>
          )}

          {/* hot sports/ e-sports */}
          {tournaments.length > 0 && (
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: '16',
                  color: 'custom.content.2',
                }}
                primary={featured}
              />
            </ListItem>
          )}
          {tournaments.map((o) => {
            const sport = _.get(sports, [o.sportId]);
            return (
              <TheItem
                key={o._id}
                label={o.name}
                image={sport?.logo}
                isPopular={o.typePopular === 1}
                isFav={o.__favorite}
                href={redirectTo(sport?.slug, o.tournamentId)}
                onFav={() => handleSelectFav({ tournamentId: o._id })}
              />
            );
          })}

          {/* All sports  */}
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                variant: '16',
                color: 'custom.content.2',
              }}
              primary={title}
            />
          </ListItem>
          {_.take(items, showAll ? items.length : sliceLength).map((o) => {
            return (
              <TheItem
                key={o._id}
                label={o.name}
                image={o.logo}
                color={o.color}
                isPopular={o.typePopular === 1}
                isFav={o.__favorite}
                isSelected={!isLive && o.slug === slug}
                href={redirectTo(o.slug)}
                onFav={() => handleSelectFav({ sportId: o._id })}
              />
            );
          })}

          {!showAll && (items?.length ?? 0) - sliceLength > 0 && (
            <ListItemButton onClick={toggleExpand}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'primary',
                }}
                primary={
                  <Trans id="left_nav.show_more">
                    Show {(items?.length ?? 0) - sliceLength} More
                  </Trans>
                }
              />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </List>
  );
};

interface TheItemProps {
  label?: React.ReactNode;
  image?: string;
  color?: string;

  isPopular?: boolean;
  isSelected?: boolean;
  isFav?: boolean;
  onFav?: () => any;

  href: MyLinkProps['href'];
}

const TheItem: React.FCC<TheItemProps> = (props) => {
  const { label, image, isPopular, isSelected, isFav, onFav, href } = props;

  const { classes, cx } = useStyles();

  return (
    <ListItemButton
      component={MyLink}
      noLinkStyle
      href={href}
      selected={isSelected}>
      <ListItemIcon sx={{ mr: 1 }}>
        <Badge
          overlap="rectangular"
          invisible={!isPopular}
          badgeContent=" "
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{ badge: classes.popular }}>
          <img width={16} height={16} alt="icon" src={image} />
        </Badge>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={label}
      />

      <FavoriteButton
        favorite={isFav ?? false}
        notFavClassName="isNotFav"
        onClick={onFav}
      />
    </ListItemButton>
  );
};
