/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from '@lingui/macro';
import { atom, useAtomValue } from 'jotai';
import _ from 'lodash';
import React from 'react';
import CasinoNavBar from 'src/components/core/casino-nav-bar';
import SportsNavBar from 'src/components/core/sports-nav-bar';
import { categoriesAtom } from 'src/store/casino';
import {
  favSportsAtom,
  favTournamentsAtom,
  liveCountAtom,
  sportAtom,
  tournamentAtom,
} from 'src/store/sport';
import { MySport, MyTournament, SportTypeEnum } from 'src/utils/types/sport';
import { CategoryList } from './components/category-list';
import { Promotion } from './components/promotion';
import { SportList, WithFav } from './components/sport-list';

interface MainLeftNavProps {
  mobile?: boolean;
}

type SportSorter = (o: WithFav<MySport | MyTournament>) => any;
const favOrPopularSorter: SportSorter[] = [
  (o) => {
    // if (o.priorityInCategory !== undefined) return +o.priorityInCategory;
    if (o.__favorite && o.typePopular === 1) return 6;

    // top priority
    if (o.__favorite) return 7;

    // second priority - not fav but popular
    if (o.typePopular) return 8;

    // not fav and not popular
    return 9;
  },
  (o) => {
    return _.toUpper(o?.name);
  },
];

const beautifulSportAtom = atom((get) => {
  const all = get(sportAtom);
  const fav = get(favSportsAtom);

  return {
    sport: _.chain(all.sport)
      .map((x) => {
        return {
          ...x,
          __favorite: !!fav[x.sportId],
        } as WithFav<MySport>;
      })
      .sortBy(favOrPopularSorter)
      .value(),
    eSport: _.chain(all.eSport)
      .map((x) => {
        return {
          ...x,
          __favorite: !!fav[x.sportId],
        } as WithFav<MySport>;
      })
      .sortBy(favOrPopularSorter)
      .value(),
  };
});

const beautifulTournamentAtom = atom((get) => {
  const all = get(tournamentAtom);
  const fav = get(favTournamentsAtom);

  const mapper = (all: MyTournament[], fav: MyTournament[]) => {
    return _.chain(all)
      .unionWith(fav, (a, b) => a.tournamentId === b.tournamentId)
      .map((x) => {
        const __favorite = !!_.find(fav, {
          tournamentId: x.tournamentId,
        });

        return {
          ...x,
          __favorite,
        } as WithFav<MyTournament>;
      })
      .filter((x) => {
        return !!x && (x.__favorite || !!x.typePopular);
      })
      .value();
  };

  return {
    sport: mapper(all.sport, fav.sport),
    eSport: mapper(all.eSport, fav.eSport),
  };
});

export const MainLeftNav: React.FCC<MainLeftNavProps> = ({ mobile }) => {
  const sport = useAtomValue(beautifulSportAtom);
  const tournament = useAtomValue(beautifulTournamentAtom);
  const categories = useAtomValue(categoriesAtom);
  const live = useAtomValue(liveCountAtom);

  return (
    <>
      {React.createElement(mobile ? CasinoNavBar : CategoryList, {
        items: categories,
        title: <Trans id="left_nav.all_casino">Casino</Trans>,
      })}
      <SportsNavBar items={sport.sport} />
      <SportsNavBar items={sport.eSport} type={SportTypeEnum.ESport} />
      {!mobile && (
        <>
          <SportList
            tournaments={tournament.sport}
            items={sport.sport}
            liveCount={live.sport}
            type={SportTypeEnum.Sport}
            header={<Trans id="sportsbook.sports">Sports</Trans>}
            liveTitle={<Trans id="sportsbook.live_now">Live sports</Trans>}
            title={<Trans id="left_nav.all_sports">All sports</Trans>}
            featured={<Trans id="left_nav.featured_sport">Hot sports</Trans>}
          />
          <SportList
            tournaments={tournament.eSport}
            items={sport.eSport}
            liveCount={live.eSport}
            type={SportTypeEnum.ESport}
            header={<Trans id="sportsbook.esports">Esports</Trans>}
            liveTitle={<Trans id="esportsbook.live_now">Live esports</Trans>}
            title={<Trans id="left_nav.all_esports">All esports</Trans>}
            featured={<Trans id="left_nav.featured_esport">Hot esports</Trans>}
          />
          <Promotion />
        </>
      )}
    </>
  );
};
