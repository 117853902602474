import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FireIcon from 'src/assets/icons/hot-event.svg?url';
import { MyLink } from 'src/components/core/mine/my-link';
import { useSportFinder } from 'src/hooks/use-current-sport';
import { Paths } from 'src/utils/constants/path';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { BaseEvent } from 'src/utils/types/event';
import { BaseOutcome } from 'src/utils/types/outcome';

interface HotEventItemProps {
  data: BaseEvent<BaseOutcome>;
}
const HotEventItem = ({ data }: HotEventItemProps) => {
  const sport = useSportFinder({ sportId: data.sportId });

  const redirect = sport
    ? generatePath(Paths.DetailFixture, {
        sportType: sport.__sportType,
        slug: sport.slug,
        eventId: data._eventSlug ?? data.eventId,
      })
    : '#';

  return (
    <ListItemButton className="normal" component={MyLink} href={redirect}>
      <ListItemIcon sx={{ mr: 1 }}>
        <Box
          component="img"
          src={FireIcon.src}
          sx={{
            width: 16,
            height: 16,
          }}
        />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={`${data.competitor1} vs ${data.competitor2}`}
      />
    </ListItemButton>
  );
};

export default HotEventItem;
