import { Trans } from '@lingui/macro';

export enum TOUR_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TOUR_TIME_STATUS {
  UPCOMING = 'UPCOMING',
  LIVE = 'LIVE',
  ENDED = 'ENDED',
  // REWARDED = 'REWARDED',
}

export const getTourTimeStatusOptions = () => [
  {
    value: TOUR_TIME_STATUS.UPCOMING,
    label: <Trans id="tournaments.time_status.upcoming">Upcoming</Trans>,
  },
  {
    value: TOUR_TIME_STATUS.LIVE,
    label: <Trans id="tournaments.time_status.live">Live</Trans>,
  },
  {
    value: TOUR_TIME_STATUS.ENDED,
    label: <Trans id="tournaments.time_status.ended">Ended</Trans>,
  },
  // { value: TOUR_TIME_STATUS.REWARDED, label: 'Rewarded' },
];
