import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    wrapper: {
      minWidth: '100%',
    },
    slideItem: {
      maxWidth: '200px',
      minWidth: '200px',
      height: '200px',
      marginRight: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '10px',
    },
    title: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginRight: '6px',
      textTransform: 'uppercase',
    },
    allText: {
      fontSize: '14px',
      color: theme.palette.primary.main,
    },
    season: {
      marginTop: '16px',
    },
    skeletonContainer: {
      '&> span': {
        transform: 'scale(1, 1)',
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
    },
  };
});
