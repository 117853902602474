import { Trans } from '@lingui/macro';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useToggle } from 'react-use';
import ArrowUp from 'src/assets/icons/arrow-up-2.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { useLeftNavHidden } from 'src/hooks/use-meta-params';
import { Paths } from 'src/utils/constants/path';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { CasinoCategory } from 'src/utils/types/casino';
import { SportTypeEnum } from 'src/utils/types/sport';
import { UrlObject } from 'url';
import { Lobby } from '../livenow-indicator';
import { useStyles } from '../sport-list/styles';

export interface CategoryListProps {
  items?: CasinoCategory[];
  title?: React.ReactNode;
}

export const CategoryList: React.FCC<CategoryListProps> = ({
  items,
  title,
}) => {
  const { classes, cx } = useStyles();
  const [open, toggle] = useToggle(true);
  const [_expand, toggleExpand] = useToggle(false);
  const { hidden, first, second, isLobby } = useLeftNavHidden(
    SportTypeEnum.Casino,
  );
  if (hidden) return null;

  const showAll = first === SportTypeEnum.Casino || _expand;
  const sliceLength = 5;

  return (
    <List disablePadding className={classes.list}>
      <ListItemButton onClick={toggle} className={classes.title}>
        <Typography
          variant="body2"
          fontWeight="bold"
          sx={{ flex: 1 }}
          fontSize={18}>
          {title}
        </Typography>

        <ListItemIcon>
          <ArrowUp width={16} className={cx(classes.arrow, open && 'rotate')} />
        </ListItemIcon>
      </ListItemButton>

      <Collapse in={open} mountOnEnter unmountOnExit={false}>
        <List disablePadding className={classes.body}>
          <Lobby selected={isLobby} />

          {_.take(
            items,
            showAll ? items?.length ?? sliceLength : sliceLength,
          ).map((item, index) => {
            const selected = !isLobby && item.categoryName === second;

            return (
              <TheItem
                data={item}
                selected={selected}
                href={generatePath(Paths.MetaCasino, {
                  category: item.categoryName,
                })}
                key={index}
              />
            );
          })}

          {!showAll && (items?.length ?? 0) - sliceLength > 0 && (
            <ListItemButton onClick={toggleExpand}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'primary',
                }}
                primary={
                  <Trans id="left_nav.show_more">
                    Show {(items?.length ?? 0) - sliceLength} More
                  </Trans>
                }
              />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </List>
  );
};

interface TheItemProps extends ListItemButtonProps {
  data: CasinoCategory;
  href: string | UrlObject;
}

const TheItem: React.FCC<TheItemProps> = ({ data, ...props }) => {
  return (
    // @ts-ignore
    <ListItemButton component={MyLink} noLinkStyle {...props}>
      {data.logo && (
        <ListItemIcon>
          <img width={16} height={16} alt="icon" src={data.logo} />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={data.categoryCustomName}
      />
    </ListItemButton>
  );
};
