import _ from 'lodash';
import { toCrypto } from 'src/hooks/use-stake-coin';
import { IGNORE_UNAUTHORIZED_HEADER } from 'src/utils/constants/keys';
import { CampaignStatus, isActiveCampaign } from 'src/utils/helpers/campaign';
import { request } from 'src/utils/request';
import { IPagination, PaginationResponse } from 'src/utils/types/response';
import { BetSlipItemInterface } from 'src/utils/types/sportsbook';
import { PlaceBetOpts } from './ticket';

interface CampaignOutcome {
  name: string;
  odds: number;
  result?: string;
  _id: string;
}

interface CampaignMarket {
  name: string;
  sportId: string;
  maxExpense: number;
  outcomes: CampaignOutcome[];
  _id: string;
}

export interface CampaignEvent {
  _id: string;
  status: CampaignStatus;
  name: string;
  preHeaderText: string;
  description: string;
  countryIds: string[];
  bannerURL?: string;
  startDateTime: string;
  endDateTime: string;
  marketName?: string;
  marketSportId?: string;
  marketExpense?: number;
  possibleOutcomes?: CampaignOutcome[];
  minStake: number;
  maxStake: number;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  markets: CampaignMarket[];
  eventURN: string;
  bannerImageURL?: string;
}

interface GetCampaignsParams {
  status?: CampaignStatus;
  page?: number;
  size?: number;
  eventURN?: string;
  sortBy?: keyof CampaignEvent;
  orderBy?: 'DESC' | 'ASC';
}

export const getCampaigns = async (params: GetCampaignsParams) => {
  const { data } = await request.get<PaginationResponse<CampaignEvent>>(
    '/promotion-campaigns/all',
    {
      params,
      headers: {
        [IGNORE_UNAUTHORIZED_HEADER]: '1',
      },
    },
  );

  const items = data?.data?.items?.filter?.(isActiveCampaign) ?? [];

  return { ...data?.data, items } as IPagination<CampaignEvent>;
};

export const getOneCampaign = async (eventURN: string) => {
  const data = await getCampaigns({
    status: CampaignStatus.ACTIVE,
    size: 1,
    page: 1,
    eventURN,
  });

  return _.first(data.items) ?? null;
};

const decimal = 18;

export const placeSingleCampaignBets = async (
  betslips: BetSlipItemInterface[],
  opts: PlaceBetOpts,
) => {
  const { coin, exchangeRate, fiat, channel, visitorId, odds_change_type } =
    opts;

  const tickets = _.map(betslips, (x) => {
    const _stake = x.singleStake ?? '0';
    const stake = fiat ? toCrypto(_stake, exchangeRate, decimal) : _stake;

    return {
      bet_game_id: x._id,
      fixture_id: x.eventId,
      odds: x.currentOdd,
      stake,
      alias: x.alias,
    };
  });

  const { data } = await request.post(
    '/promotion-campaigns/place-bet',
    {
      data: tickets,
      coin,
      channel,
      odds_change_type,
    },
    { headers: { visitorId } },
  );

  return tickets;
};
