import { Trans } from '@lingui/macro';
import {
  Box,
  keyframes,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import LobbyIcon from 'src/assets/icons/lobby.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { Paths } from 'src/utils/constants/path';

interface RippleDotProps {
  inactive?: boolean;
}

const ripple = keyframes`
  from {
    transform: scale(.8);
    opacity: 1;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
`;

const RippleDot: React.FCC<RippleDotProps> = () => {
  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          color: 'custom.status.green',
          backgroundColor: 'currentcolor',
          position: 'relative',
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: `${ripple} 1.2s infinite ease-in-out`,
            boxShadow: `0px 0px 2px 2px currentcolor`,
            content: '""',
          },
        }}
      />
    </Box>
  );
};

interface LiveNowIndicatorProps extends ListItemButtonProps<'a'> {
  count?: number;
  pathname?: string;
  liveNowTitle: React.ReactNode;
}

export const LiveNowIndicator = React.forwardRef<any, LiveNowIndicatorProps>(
  ({ count, pathname = '/', liveNowTitle, ...props }, ref) => {
    return (
      <ListItemButton
        {...props}
        ref={ref}
        component={MyLink}
        noLinkStyle
        href={pathname}>
        <ListItemIcon>
          <RippleDot />
        </ListItemIcon>
        <Typography variant="body2">
          {liveNowTitle}
          {`${_.isNil(count) ? '' : ` (${count})`}`}
        </Typography>
      </ListItemButton>
    );
  },
);

LiveNowIndicator.displayName = 'LiveNowIndicator';

export const Lobby: React.FCC<ListItemButtonProps<'a'>> = (props) => {
  return (
    <ListItemButton
      {...props}
      component={MyLink}
      noLinkStyle
      href={Paths.LobbyBetUTopPicks}>
      <ListItemIcon>
        <LobbyIcon />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={<Trans id="left_nav.lobby">Lobby</Trans>}
      />
    </ListItemButton>
  );
};
