import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import Image from 'next/image';
import { useMemo } from 'react';
import ESportsIcon from 'src/assets/icons/esports.svg?url';
import SportsIcon from 'src/assets/icons/sports.svg?url';
import { useNavList } from 'src/components/modules/left-side-nav/components/use-nav-list';
import { useLeftNavHidden } from 'src/hooks/use-meta-params';
import { isValidHttpUrl } from 'src/utils/helpers/etc';
import { MySport, SportTypeEnum } from 'src/utils/types/sport';
import { makeStyles } from 'tss-react/mui';
import { NavigationCarousel } from '../navigation-carousel';

type SportsNavBarProps = {
  items?: MySport[];
  type?: SportTypeEnum;
};

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '60px',
  },
  textContainer: {
    marginTop: '4px',
    display: 'table',
    tableLayout: 'fixed',
    textAlign: 'center',
    width: '100%',
  },
  text: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
  },
}));

const SportsNavBar = ({
  items,
  type = SportTypeEnum.Sport,
}: SportsNavBarProps) => {
  const { hidden } = useLeftNavHidden(type);

  const { classes, cx } = useStyles();
  const { redirectTo } = useNavList(type);

  const renderTitle = (item: {
    logo?: string | StaticImageData;
    name: React.ReactNode;
  }) => {
    const url = _.isString(item.logo)
      ? isValidHttpUrl(item.logo)
        ? item.logo
        : null
      : item.logo?.src;

    return (
      <div className={classes.titleContainer}>
        <Image width={16} height={16} src={url || '/null'} alt="" />
        <Box className={classes.textContainer}>
          <Typography fontSize={11} className={classes.text}>
            {item.name}
          </Typography>
        </Box>
      </div>
    );
  };

  const itemsToDisplay = useMemo(() => {
    const sortedTabs =
      items?.map((item) => ({
        path: redirectTo(item.slug),
        title: renderTitle(item),
      })) ?? [];
    return [
      {
        path: type === SportTypeEnum.Sport ? '/sport/live' : '/esport/live',
        title: renderTitle({
          name:
            type === SportTypeEnum.Sport ? (
              <Trans id="sportsbook.live_now">Live sports</Trans>
            ) : (
              <Trans id="esportsbook.live_now">Live esports</Trans>
            ),
          logo: type === SportTypeEnum.Sport ? SportsIcon : ESportsIcon,
        }),
      },
      ...(sortedTabs || []),
    ];
  }, [items, type]);

  if (hidden) {
    return null;
  }

  return <NavigationCarousel items={itemsToDisplay} />;
};

export default SportsNavBar;
