import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import Image from 'next/image';
import { useMemo } from 'react';
import Lobby from 'src/assets/icons/lobby.svg';
import { CategoryListProps } from 'src/components/modules/left-side-nav/components/category-list';
import { useLeftNavHidden } from 'src/hooks/use-meta-params';
import { Paths } from 'src/utils/constants/path';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { SportTypeEnum } from 'src/utils/types/sport';
import { NavigationCarousel } from '../navigation-carousel';

const lobby = {
  path: [
    Paths.LobbyBetUTopPicks,
    Paths.LobbyNewRelease,
    Paths.LobbyProviders,
    Paths.LobbyFavorite,
  ],
  categoryName: 'lobby',
  title: (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div>
        <Lobby style={{ height: '16px' }} />
      </div>
      <Typography style={{ marginTop: '4px' }} fontSize={11}>
        <Trans id="left_nav.lobby">Lobby</Trans>
      </Typography>
    </div>
  ),
};

const FALLBACK_IMAGE =
  'https://dev-ubet-fantasy-logos.s3.ap-southeast-1.amazonaws.com/stg-1703584484109.svg';

const CasinoNavBar: React.FC<CategoryListProps> = ({ items }) => {
  const { hidden } = useLeftNavHidden(SportTypeEnum.Casino);

  const tabs = useMemo(() => {
    const allItems =
      items?.map((item) => ({
        path: generatePath(Paths.MetaCasino, {
          category: item.categoryName,
        }),
        title: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div>
              <Image
                width={16}
                height={16}
                src={item.logo ?? FALLBACK_IMAGE}
                alt={item.categoryCustomName}
              />
            </div>
            <Typography style={{ marginTop: '4px' }} fontSize={11}>
              {item.categoryCustomName}
            </Typography>
          </div>
        ),
      })) || [];
    return [lobby, ...allItems];
  }, [items]);

  if (hidden) return null;

  return <NavigationCarousel items={tabs || []} />;
};

export default CasinoNavBar;
